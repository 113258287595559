document.addEventListener('turbolinks:load', () => {
  $(() => {
    const productDetailOfFree = document.querySelector('#productDetailOfFree');
    const productDetailOfAppeal = document.querySelector('#productDetailOfAppeal');
    const productDetailOfProperty = document.querySelector('#productDetailOfProperty');
    
    if (productDetailOfFree) {
      ReactDOM.render(
        <App
          kind={JSON.parse(productDetailOfFree.getAttribute('kind'))}
          titleList={JSON.parse(productDetailOfFree.getAttribute('titleList'))}
        />,
        productDetailOfFree,
      );
    }
    
    if (productDetailOfAppeal) {
      ReactDOM.render(
        <App
          kind={JSON.parse(productDetailOfAppeal.getAttribute('kind'))}
          titleList={JSON.parse(productDetailOfAppeal.getAttribute('titleList'))}
        />,
        productDetailOfAppeal,
      );
    }

    if (productDetailOfProperty) {
      ReactDOM.render(
        <App
          kind={JSON.parse(productDetailOfProperty.getAttribute('kind'))}
          titleList={JSON.parse(productDetailOfProperty.getAttribute('titleList'))}
        />,
        productDetailOfProperty,
      );
    }
  });
});

